import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import H1 from 'components/Layout/H1';

const Legal = (): ReactElement => {
    const { t, i18n } = useTranslation('static');

    return (
        <React.Fragment>
            <Helmet>
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_SITE_URL}${
                        i18n.language === 'fr' ? 'mentions-legales' : 'legal-notice'
                    }`}
                />
                <meta property="og:site_name" content={t('static:legal.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={t('static:legal.meta.og.title')} />
                <meta property="og:description" content={t('static:legal.meta.og.description')} />
                <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}images/logo.png`} />
                <meta property="twitter:title" content={t('static:legal.meta.twitter.title')} />
                <meta property="twitter:description" content={t('static:legal.meta.twitter.description')} />
                <meta property="twitter:image" content={`${process.env.REACT_APP_SITE_URL}images/logo.png`} />
                <meta name="twitter:card" content="summary" />
                <meta property="title" content={t('static:legal.meta.title')} />
                <title>{t('static:legal.meta.title')}</title>
                <meta property="description" content={t('static:legal.meta.description')} />
            </Helmet>
            <Container>
                <Row>
                    <Col xs={12} className="text-center mb-4">
                        <H1>
                            <Trans t={t}>static:legal.title</Trans>
                        </H1>
                    </Col>
                    <Col xs={12}>
                        <h2>
                            <Trans t={t}>static:legal.p1.title</Trans>
                        </h2>
                        <p>
                            <Trans t={t}>static:legal.p1.content</Trans>
                        </p>
                        <h2>
                            <Trans t={t}>static:legal.p2.title</Trans>
                        </h2>
                        <p>
                            <Trans t={t}>static:legal.p2.content</Trans>
                        </p>
                        <h2>
                            <Trans t={t}>static:legal.p3.title</Trans>
                        </h2>
                        <p>
                            <Trans t={t}>static:legal.p3.content</Trans>
                        </p>
                        <h2>
                            <Trans t={t}>static:legal.p4.title</Trans>
                        </h2>
                        <h3>
                            <Trans t={t}>static:legal.p4.s1.title</Trans>
                        </h3>
                        <p>
                            <Trans t={t}>static:legal.p4.s1.content</Trans>
                        </p>
                        <h3>
                            <Trans t={t}>static:legal.p4.s2.title</Trans>
                        </h3>
                        <p>
                            <Trans t={t}>static:legal.p4.s2.content</Trans>
                        </p>
                        <h2>
                            <Trans t={t}>static:legal.p5.title</Trans>
                        </h2>
                        <h3>
                            <Trans t={t}>static:legal.p5.s1.title</Trans>
                        </h3>
                        <p>
                            <Trans t={t}>static:legal.p5.s1.content</Trans>
                        </p>
                        <h3>
                            <Trans t={t}>static:legal.p5.s2.title</Trans>
                        </h3>
                        <p>
                            <Trans t={t}>static:legal.p5.s2.content</Trans>
                        </p>
                        <h2>
                            <Trans t={t}>static:legal.p6.title</Trans>
                        </h2>
                        <h3>
                            <Trans t={t}>static:legal.p6.s1.title</Trans>
                        </h3>
                        <p>
                            <Trans t={t}>static:legal.p6.s1.content</Trans>
                        </p>
                        <h3>
                            <Trans t={t}>static:legal.p6.s2.title</Trans>
                        </h3>
                        <p>
                            <Trans t={t}>static:legal.p6.s2.content</Trans>
                        </p>
                        <h3>
                            <Trans t={t}>static:legal.p6.s3.title</Trans>
                        </h3>
                        <h4>
                            <Trans t={t}>static:legal.p6.s3.ss1.title</Trans>
                        </h4>
                        <p>
                            <Trans t={t}>static:legal.p6.s3.ss1.content</Trans>
                        </p>
                        <h4>
                            <Trans t={t}>static:legal.p6.s3.ss2.title</Trans>
                        </h4>
                        <p>
                            <Trans t={t}>static:legal.p6.s3.ss2.content</Trans>
                        </p>
                        <h4>
                            <Trans t={t}>static:legal.p6.s3.ss3.title</Trans>
                        </h4>
                        <p>
                            <Trans t={t}>static:legal.p6.s3.ss3.content</Trans>
                        </p>
                        <h4>
                            <Trans t={t}>static:legal.p6.s3.ss4.title</Trans>
                        </h4>
                        <p>
                            <Trans t={t}>static:legal.p6.s3.ss4.content</Trans>
                        </p>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Legal;
